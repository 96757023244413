html {
  background-color: #000000;
  height: 100%;
  scroll-behavior: auto !important;
}

body {
  background-color: #000000;
  height: 100%;
}

/*fonts*/
@font-face {
  font-family: 'PramukhRoundedBold';
  src: url(fonts/PramukhRoundedBold.otf);
}

/*App*/
.App {
  /* max-width: 540px; */
  margin: auto;
}

#root {
  height: 100%;
}

.bg-body {
  background-color: #eeeeee !important;
}

.round-top {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.fs-7 {
  font-size: 0.8rem;
}

.fs-8 {
  font-size: 0.7rem;
}

.fs-6 {
  font-size: 0.55rem;
}

.fs-12px{
  font-size: 12px;
}
.d-content {
  display: contents;
}

.p-icon {
  padding: 2px;
}

.vr {
  border-left: 1px solid #fff;
  height: 50px;
  margin-top: 5px;
  opacity: 1 !important;
}

.top-banner {
  max-height: 300px;
  object-fit: cover;
}

.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  /* or use 'block' if necessary */
}

.background-image {
  display: block;
  width: 100%;
  /* Adjust as needed */
  height: auto;
  /* Maintain aspect ratio */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(7, 90, 119, 0.6), rgba(7, 90, 119, 0.3));
  /* Adjust colors and opacity as needed */
}

.overlay-image {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Adjust the size of the overlay image as needed */
  /* width: 150px; */
}

.bottom-right-button {
  position: absolute;
  bottom: 50px;
  right: 10px;
  background-color: #FF8772 !important;
  width: 165px;
  --bs-btn-color: #fff !important;
  --bs-btn-border-color: #FF8772 !important;
  border-radius: 15px;
}

.alert-top {
  width: 95%;
  margin: 0px auto 10px;
  background-color: #003594;
  color: #fff;
  border-radius: 20px;
}

.alert-breakfast {
  width: 95%;
  margin: 0px auto 10px;
  background-color: #003594;
  color: #fff;
  border-radius: 20px;
  font-family: "GothamBold";
  font-size: 12px;
}

.alert-top-icon {
  width: 30px !important;
  height: 30px !important;
  filter: brightness(0) invert(1) grayscale(1);
}

.alert-top-logo {
  height: 65px;
}

/*nav*/
.nav {
  overflow-x: auto;
  flex-wrap: nowrap !important;
  scrollbar-width: thin;
  background-color: #fff;
}

.nav::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.active-link p {
  color: #79A3DB !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fbb040;
  background-color: #3d9cb4;
  border-bottom: 2px #fbb040 solid;
  border-radius: unset;
}

.nav-link {
  font-family: GothamBold;
  color: #C7D6EA !important;
  display: block ruby !important;
  white-space: nowrap !important;
}

#navbar-cat {
  width: 95%;
  max-height: 60px;
  margin: 0px auto 10px;
}

.nav-img {
  width: 100%;
  max-height: 45px;
  padding: 3px 0px;
  cursor: pointer;
}

.w-logo {
  width: 400px;
}

li {
  position: relative;
  padding-left: 5px;
}

ul li:first-child:before {
  padding-left: 0px;
  content: none;
}

ul:not(.shisha-list) li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: 5px;
  color: #3d97af;
}

/*fonts*/
@font-face {
  font-family: 'GothamBold';
  src: url(fonts/GothamBold.otf);
}

@font-face {
  font-family: 'Cornerstone';
  src: url(fonts/Cornerstone.ttf);
}

@font-face {
  font-family: 'MaisonNeueBook';
  src: url(fonts/MaisonNeueBook.ttf);
}

/* menu */
h2 {
  font-size: 4rem !important;
}

h5,
span {
  font-family: GothamBold;
}

.h5,
h5 {
  font-size: 1rem;
}

p {
  font-family: MaisonNeueBook;
}

.text-cal {
  font-size: 8px;
  font-family: MaisonNeueBook;
}

.active-item {
  color: #fbb040;
  /* border-bottom: 2px #fbb040 solid; */
}

.active-item .nav-link {
  color: #FF8772 !important;
}

.scroll-mt-50 {
  scroll-margin-top: 180px;
}

.bg-smokey {
  background-color: #3d9cb4;
}

.mh-125 {
  min-height: 125px;
}

.h-product-card {
  min-height: 450px;
}

.h-350 {
  height: 350px;
}

.mt-n40 {
  margin-top: -40px;
}

.mtn-1 {
  margin-top: -1px;
}

.w-btn {
  width: 150px;
}

.card-title {
  color: #FF8772;
  /* white-space: nowrap !important; */
}

.card-price {
  color: #79A3DB !important;
  font-size: 13px;
}

.modal-body {
  padding: 0px;
}

.card-product-image {
  height: 175px;
}

.btn-white {
  background-color: #fff !important;
}

.btn {
  --bs-btn-padding-y: 0.44rem !important;
}

.btn-scroll-top {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 50px;
  height: 50px;
  color: #fff;
  z-index: 999;
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #075a77;
  --bs-btn-border-color: #075a77;
  --bs-btn-hover-color: #075a77;
  --bs-btn-hover-bg: #075a77;
  --bs-btn-hover-border-color: #075a77;
  --bs-btn-active-color: #075a77;
  --bs-btn-active-bg: #075a77;
  --bs-btn-active-border-color: #075a77;
}

.btn-scroll-top img {
  width: 25px;
}

.card-img-top-shisha{
  width: 396px;
}

.card-text {
  color: #003594;
}

.card-shisha{
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.btn-qrpage{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 75px;
  border: none;
  border-radius: 30px;
  background-color: #76797e;
  color: #ffce07;
  font-family: 'PramukhRoundedBold';
  font-size: 50px;
}

.btn-location:hover{
  color: #ffce07;
  background-color: #4e4f51;
  border-color: #4e4f51;
}

@media only screen and (max-width: 500px) {
  .nav {
    scrollbar-width: none;
  }

  .h5,
  h5 {
    font-size: 12px;
  }

  .description-font {
    font-size: 10px;
  }

  .w-logo {
    width: 200px;
  }

  .vr {
    height: 25px;
    margin-top: 5px;
  }

  .card-product-image {
    height: 175px;
  }

  .bottom-right-button {
    width: 90px;
    height: 30px;
  }

  .alert-top-icon {
    width: 15px !important;
    height: 15px !important;
    margin-top: -15px;
    margin-left: 5px;
  }

  .alert-top-logo {
    height: 35px;
  }

  .top-banner {
    max-height: 225px;
  }

  .nav-img {
    width: 100%;
  }

  .fs-4 {
    font-size: 0.7rem !important;
  }

  .fs-8 {
    font-size: 0.56rem;
  }

  .fs-6 {
    font-size: 0.45rem !important;
  }

  .modal-w {
    width: 400px;
  }

  .h-product-card {
    min-height: 330px;
  }

  .h-product-card-modal {
    height: 430px;
  }

  .h-product-card {
    min-height: 385px;
  }

  .modal-dialog {
    margin: auto !important;
  }

  #navbar-cat {
    height: 50px;
  }

  .shisha {
    line-height: 15px;
    padding-right: 15px;
  }

  .card-shisha{
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .h-product-card {
    min-height: 335px;
  }

  .fs-3 {
    font-size: 1.25rem !important;
  }
}

@media only screen and (max-width: 400px) {
  .fs-4 {
    font-size: 0.7rem !important;
  }

  .alert-top-icon {
    width: 15px !important;
    height: 15px !important;
    margin-top: -15px;
    margin-left: 5px;
  }
}